import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Image,
  Checkbox,
  Table,
} from 'antd';
import getLocations from 'src/Assets/Content/statesFn';
import type { DatePickerProps, FormInstance } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import ErrorMessage from 'src/Components/Shared/ErrorMessage';
import SubHeading from 'src/Components/Shared/SubHeading';
import { FormWraper } from 'src/Styles/global';
import { useRef, useState, ChangeEvent, useEffect } from 'react';
import Print from 'src/Assets/Icons/print-icon.svg';
import Trash from 'src/Assets/Icons/trash.svg';
import DropDownIcon from 'src/Assets/Icons/DropDown';
import CalenderIcon from 'src/Assets/Icons/Calender';
import Modal from 'antd/es/modal/Modal';
import FormActionButn from 'src/Components/Shared/FormActionButn';
import FormItem from 'antd/es/form/FormItem';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useLocation } from 'react-router-dom';
import {
  AddApplication,
  SearchJobAction,
  VarifyJobAction,
} from 'src/Redux/App/Actions/Jobs';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RecruitmentWrapper } from '../Recruitment/styles';
import { loading as stateLoading } from 'src/Redux/App';

import axios from 'axios';
import WarningIcon from 'src/Assets/Icons/WarningIcon';
import NotificationModal from 'src/Components/Pages/Auth/NotificationModal';
interface PackageData {
  id: number;
  title: string;
  price: number;
  discription: string;
}
const ApplicationDetails = () => {
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null!);
  const locations: any[] = getLocations();
  const location: any = useLocation();
  const loading = useSelector(stateLoading);
  const record = location.state && location.state.record;
  const [content, setContent] = useState<string | null>('');
  const [notificationContant, setNotificationContant] = useState<string | null>(
    ''
  );
  const [agentsData, setAgentData] = useState({
    agentData: {
      agent: '',
      contactPerson: '',
      agentAddress: '',
      agentState: '',
      agentCity: '',
      zipCode: '',
      contactPhone: '',
      contactEmail: '',
    },
    invoiceCopyTo: '',
    PSUSA_status: '',
    resumeTo_PSUSA: false,
    storeDate: '',
    submitResume: '',
  });
  const [SubmitResumeToPSUSA, setSubmitResumeToPSUSA] = useState(true);
  const [accountHolderData, setAccountHolderData] = useState({
    companyName: '',
    firstName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    email: '',
  });
  const [isModalVisible, setModalVisible] = useState(false);
  const [managerTitle, setManagerTitle] = useState(
    record?.employerInfo?.hiringManagerTitle === 'Other' ? true : false
  );
  const [managerTitleName, setManagerTitleName] = useState('');
  const [mail, setPSEmail] = useState(false);
  const [radioCheck, setRadioCheck] = useState('uncheck');
  const [resumeCkeck, setResumeCheck] = useState(false);

  useEffect(() => {
    const obj = {
      PSUSA_status: record?.PSUSA_status,
      agentData: record?.agentData,
      invoiceCopyTo: record?.invoiceCopyTo,
      resumeTo_PSUSA: record?.resumeTo_PSUSA,
      storeDate: record?.storeDate,
      submitResume: record?.submitResume,
    };
    if (!record.accountHolderData) {
      const objForAccount = {
        companyName: record?.user?.companyName,
        firstName: record?.user?.firstName,
        address: record?.user?.address,
        city: record?.user?.city,
        state: record?.user?.state,
        zipCode: String(record?.user?.zipCode),
        phone: record?.user?.phone,
        email: record?.user?.users_email,
      };
      setAccountHolderData(objForAccount);
    } else {
      const objForAccount = {
        companyName: record?.accountHolderData?.companyName,
        firstName: record?.accountHolderData?.firstName,
        address: record?.accountHolderData?.address,
        city: record?.accountHolderData?.city,
        state: record?.accountHolderData?.state,
        zipCode: String(record?.accountHolderData?.zipCode),
        phone: record?.accountHolderData?.phone,
        email: record?.accountHolderData?.email,
      };
      setAccountHolderData(objForAccount);

      const title =
        record.employerInfo.hiringManagerTitle !== 'Mr.' &&
        record.employerInfo.hiringManagerTitle !== 'Mrs.' &&
        record.employerInfo.hiringManagerTitle !== 'Ms.' &&
        record.employerInfo.hiringManagerTitle !== 'Ms.' &&
        record.employerInfo.hiringManagerTitle !== 'Dr.'
          ? record.employerInfo.hiringManagerTitle
          : '';
      setManagerTitle(
        record.employerInfo.hiringManagerTitle === 'Other' ? true : false
      );
      setManagerTitleName(title);
    }
    setAgentData(obj);
    if (
      (record?.submitResume == undefined || record?.submitResume == '') &&
      record?.resumeTo_PSUSA !== null
    ) {
      // The value is either undefined or an empty string
      setResumeCheck(true);
    } else {
      // The value is defined and not empty
      setResumeCheck(false);
    }
    setCheckedItems(
      Object.keys(record).length === 0 ? defaultItems : record?.diaplayItem
    );
    if (Object.keys(record).length !== 0) {
      setPSEmail(!record?.resumeTo_PSUSA);
    } else {
      setPSEmail(false);
    }
  }, [1000]);
  const [job, setJob] = useState(record);
  const defaultItems = {
    salary: true,
    jobNumber: true,
    companyName: true,
    discription: true,
    noOfEmployee: true,
    submitResume: true,
    companyNature: true,
    recruiterFirstName: true,
    recruiterLastName: true,
    multiPosition: true,
    specialSkills: true,
    telecommuting: true,
    worksiteStreet: true,
    travelRequirements: true,
    specialInstructions: true,
    educationAndExperience: true,
  };
  const [checkedItems, setCheckedItems] = useState(
    Object.keys(record).length === 0 ? defaultItems : record?.displayItem
  );
  const onChange = (e: RadioChangeEvent) => {
    setRadioCheck('checked');
    setPSEmail(!e.target.value);
    setAgentData({ ...agentsData, resumeTo_PSUSA: e.target.value });
    setJob({ ...job, ['resumeTo_PSUSA']: e.target.value });
    setResumeCheck(!e.target.value);
  };
  const editorRefs: React.RefObject<HTMLDivElement>[] = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const handleCheckboxChange = (e: any) => {
    const { name, checked } = e.target;

    setCheckedItems((checkedItems: any) => {
      // Check if the checkbox is checked or unchecked
      if (checked) {
        // If checked, add the key to the data with value true
        return { ...checkedItems, [name]: true };
      } else {
        // If unchecked, remove the key from the data
        const newData: any = { ...checkedItems };
        delete newData[name];
        return newData;
      }
    });
  };
  const { Option } = Select;
  const handleCKEditorChange1 = (event: any, editor: any) => {
    const data = editor.getData(); // Get the content from the CKEditor
    setJob({ ...job, discription: data }); // Update the state with the new content
  };
  const handleCKEditorChange2 = (event: any, editor: any) => {
    const data = editor.getData(); // Get the content from the CKEditor
    setJob({ ...job, educationAndExperience: data }); // Update the state with the new content
  };

  const calculateEndDate = (date: any, duration: any) => {
    // Ensure startDate is a valid date string
    const formattedDate: any = new Date(date);
    if (isNaN(formattedDate)) {
      return;
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Set hours to 0 for consistency
    let newDate: any;
    let usaTimeZones = ['Asia/Karachi'];
    let formattedMonth: any;
    let formattedDay: any;
    let formattedYear: any;
    if (usaTimeZones.includes(timeZone)) {
      formattedDate.setHours(0, 0, 0, 0);
      let datePart: any = formattedDate.toISOString().split('T')[0];
      // Convert jobDuration to a number
      let jobDurationNumber = Number(duration);
      let endDate: any = calculateRecruitmentEndDate(
        datePart,
        jobDurationNumber
      );
      let newEndDate = '';
      newDate = dayjs(endDate, 'YYYY-MM-DD');
      newDate = newDate.add(1, 'day');
      newEndDate = newDate.format('YYYY-MM-DD');
      const [year, month, day] = newEndDate.split('-').map(Number);
      formattedMonth = month.toString().padStart(2, '0');
      formattedDay = day.toString().padStart(2, '0');
      formattedYear = year;
    } else {
      let datePart: any = formattedDate.toISOString().split('T')[0];
      let jobDurationNumber = Number(duration);
      let endDate: any = calculateRecruitmentEndDate(
        datePart,
        jobDurationNumber
      );
      let newEndDate = '';
      newDate = dayjs(endDate, 'YYYY-MM-DD');
      let parsedDate = dayjs(newDate, 'ddd, DD MMM YYYY HH:mm:ss [GMT]');
      newDate = parsedDate.format('YYYY-MM-DD');
    }
    let endUpdatedDate =
      formattedMonth && formattedDay && formattedYear
        ? `${formattedYear}-${formattedMonth}-${formattedDay}`
        : newDate;
    return formattedMonth && formattedDay && formattedYear
      ? `${formattedYear}-${formattedMonth}-${formattedDay}`
      : newDate;
  };
  const varifyData = () => {
    const data: any = {
      jobFrom: 'Admin Panel',
      jobTitle: job?.jobTitle,
      multiPosition: job?.multiPosition,
      discription: job?.discription,
      educationAndExperience: job?.educationAndExperience,
      requiredSkills: job?.requiredSkills,
      travelRequirements: job?.travelRequirements,
      telecommuting: job?.telecommuting,
      salary: job?.salary,
      specialSkills: job?.specialSkills,
      telecommutingCondition: job?.telecommuting
        ? job?.telecommutingCondition
        : '',
      employerInfo: {
        companyName: job?.employerInfo?.companyName,
        noOfEmployee: job?.employerInfo?.noOfEmployee,
        recruiterFirstName: job?.employerInfo?.recruiterFirstName,
        recruiterLastName: job?.employerInfo?.recruiterLastName,
        hiringManagerTitle:
          job?.employerInfo?.hiringManagerTitle &&
          job?.employerInfo?.hiringManagerTitle == 'Other'
            ? managerTitleName
            : job?.employerInfo?.hiringManagerTitle,
        companyNature: job?.employerInfo?.companyNature,
        worksiteStreet: job?.employerInfo?.worksiteStreet,
        city: job?.employerInfo?.city,
        state: job?.employerInfo?.state,
        worksiteZipCode: job?.employerInfo?.worksiteZipCode,
      },
      specialInstructions: job?.specialInstructions,
      referenceCode: job?.referenceCode,
      recruitmentFirm: job?.recruitmentFirm,
      startDate: job?.startDate,
      jobDuration: Number(job?.jobDuration),
      endDate: job?.endDate,
      accountHolderData: accountHolderData,
      submitResume: job?.submitResume || '',
    };

    if (job?.user) {
      data.userId = job?.user?.id;
    }
    if (data.startDate && data.jobDuration) {
      // Ensure startDate is a valid date string
      const formattedDate: any = new Date(data.startDate);

      if (isNaN(formattedDate)) {
        return;
      }

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Set hours to 0 for consistency

      let usaTimeZones = ['Asia/Karachi'];
      let formattedMonth: any;
      let formattedDay: any;
      let formattedYear: any;
      let newDate: any;
      if (usaTimeZones.includes(timeZone)) {
        // formattedDate.setHours(0, 0, 0, 0);
        let datePart: any = formattedDate.toISOString().split('T')[0];
        // Convert jobDuration to a number
        let jobDurationNumber = Number(data.jobDuration);
        let endDate: any = calculateRecruitmentEndDate(
          datePart,
          jobDurationNumber
        );
        let newEndDate = '';
        newDate = dayjs(endDate, 'YYYY-MM-DD');
        newDate = newDate.add(0, 'day');
        newEndDate = newDate.format('YYYY-MM-DD');
        const [year, month, day] = newEndDate.split('-').map(Number);
        formattedMonth = month.toString().padStart(2, '0');
        formattedDay = day.toString().padStart(2, '0');
        formattedYear = year;
      } else {
        // formattedDate.setHours(0, 0, 0, 0);
        let datePart: any = formattedDate.toISOString().split('T')[0];
        // Convert jobDuration to a number
        let jobDurationNumber = Number(data.jobDuration);
        let endDate: any = calculateRecruitmentEndDate(
          datePart,
          jobDurationNumber
        );
        let newEndDate = '';
        newDate = dayjs(endDate, 'YYYY-MM-DD');
        let parsedDate = dayjs(newDate, 'ddd, DD MMM YYYY HH:mm:ss [GMT]');
        newDate = parsedDate.format('YYYY-MM-DD');
      }

      data.endDate =
        formattedMonth && formattedDay && formattedYear
          ? `${formattedYear}-${formattedMonth}-${formattedDay}`
          : newDate;
    }
    const isStateValid = (obj: any) => {
      return Object.keys(obj).every(
        (key) => obj[key] !== '' && obj[key] !== undefined
      );
    };
    if (isStateValid(accountHolderData)) {
      if (
        (job.resumeTo_PSUSA !== null && job.resumeTo_PSUSA !== undefined) ||
        job.resumeTo_PSUSA ||
        (agentsData.resumeTo_PSUSA !== null &&
          agentsData.resumeTo_PSUSA !== undefined) ||
        agentsData.resumeTo_PSUSA
      ) {
        if (localStorage.getItem('add')) {
          if (localStorage.getItem('pakageId')) {
            dispatch<any>(
              AddApplication(
                Object.keys(agentsData)?.length === 0
                  ? job?.agentData
                  : agentsData,
                data,
                checkedItems,
                navigate
              )
            );
          } else {
            setNotificationContant('Please select one of the package');
            setModalVisible(true);
          }
        } else {
          let result = job?.jobNumber?.includes('-')
            ? job?.jobNumber.split('-')[1]
            : job?.jobNumber;

          data.jobNumber = result;
          dispatch<any>(
            VarifyJobAction(
              job?.id,
              Object.keys(agentsData)?.length === 0
                ? job?.agentData
                : agentsData,
              data,
              checkedItems,
              navigate
            )
          );
        }
      } else {
        setNotificationContant(
          'Please select option for Submit Resume to PSUSA'
        );
        setSubmitResumeToPSUSA(true);
        setModalVisible(true);
      }
    } else {
      setNotificationContant('Please fill all the agent data fields');
      setModalVisible(true);
    }
  };

  const calculateRecruitmentEndDate = (startDate: string, days: number) => {
    const start = new Date(startDate);
    start.setDate(start.getDate() + days);
    return start.toISOString().split('T')[0];
  };
  const Discard = () => {
    navigate('/');
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [PackagesData, setPackagesData] = useState<PackageData[]>([]);
  const [loadingData, setloadingData] = useState(false);
  useEffect(() => {
    if (record?.packagesId) {
      setSelectedValue(record?.packagesId);
    }

    const fetchData = async () => {
      try {
        setloadingData(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/pakages/list`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            data: {},
          }
        );
        // Assuming the response data is an array of objects with properties: value, price, and label
        setPackagesData(response.data);
        setloadingData(false);
      } catch (error) {
        setloadingData(false);
        console.error('Error fetching radio data:', error);
      }
    };
    fetchData();
  }, []);

  // @ts-ignore
  const getRowClassName = (record: any, index: number) => {
    return index % 2 === 0 ? 'table-row-White' : 'table-row-lite';
  };
  const [selectedValue, setSelectedValue] = useState(
    record?.packagesId ? record?.packagesId : null
  );
  const handleRadioChange = (e: any) => {
    localStorage.setItem(
      'pakageId',
      String(PackagesData[e.target.value - 1].id)
    );
    setSelectedValue(e.target.value);
  };
  const closeModal = () => {
    setModalVisible(false);
  };
  const onFinishFailed = (errorInfo: any) => {
    setNotificationContant('Please fill all required fields');
    setModalVisible(true);
  };

  useEffect(() => {}, [agentsData, accountHolderData, job]);
  return (
    <>
      <RecruitmentWrapper>
        <NotificationModal
          visible={isModalVisible}
          onClose={closeModal}
          content={notificationContant}
          icon={<WarningIcon />}
        />
        <Row>
          <Col
            xs={20}
            sm={20}
            md={20}
            lg={20}
            xl={20}
            xxl={20}
          >
            <SubHeading>Recruitment Packages</SubHeading>
          </Col>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            xxl={4}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              gap: '8px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <FormActionButn>
                <Image
                  src={Print}
                  preview={false}
                  className='print-icon'
                  onClick={() => window.print()}
                  style={{
                    marginTop: '2px',
                  }}
                />
              </FormActionButn>
              <FormActionButn>
                <Image
                  src={Trash}
                  preview={false}
                  className='print-icon'
                />
              </FormActionButn>
            </div>
          </Col>
          <div className='Radio-wrapper'>
            <Radio.Group
              onChange={handleRadioChange}
              value={selectedValue}
            >
              <Space direction='vertical'>
                {PackagesData.map((option) => (
                  <Radio
                    key={option.id}
                    value={option.id}
                  >
                    <span className='price'>${option.price}</span>
                    <span className='package-heading'>{option.title}: </span>
                    <span className='label'>{option.discription}</span>
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        </Row>
      </RecruitmentWrapper>

      <Row justify={'space-between'}>
        <Col
          xs={20}
          sm={20}
          md={20}
          lg={20}
          xl={20}
          xxl={20}
        >
          <SubHeading>Client Application Details</SubHeading>
        </Col>
      </Row>
      <Row>
        <Col md={16}>
          <FormWraper>
            <Form
              layout='vertical'
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              onFinish={varifyData}
              ref={(form) => {
                formRef.current = form!;
              }}
              initialValues={{
                employerCompanyName: record?.accountHolderData?.companyName,
                firstName: record?.accountHolderData?.firstName,
                address: record?.accountHolderData?.address,
                employerCity: record?.accountHolderData?.city,
                employerState: record?.accountHolderData?.state,
                employerZipCode: record?.accountHolderData?.zipCode
                  ? String(record?.accountHolderData?.zipCode)
                  : '',
                phone: record?.accountHolderData?.phone,
                email: record?.accountHolderData?.email,
                resumeTo_PSUSA: job?.resumeTo_PSUSA,
                submitResume: job?.submitResume,
                jobDuration: job?.jobDuration,
                endDate: job?.endDate && dayjs(job.endDate),
                multiPosition: job?.multiPosition,
              }}
            >
              {/* Job Title */}
              <Form.Item
                label='Job Title'
                name='jobTitle'
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message='Please input Job Title' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.jobTitle}
                      defaultValue={job?.jobTitle}
                      onChange={(e) =>
                        setJob({ ...job, jobTitle: e.target.value })
                      }
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Client Job Number */}

              <Form.Item
                label='Client Job Number'
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message='Please input Job Number' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      disabled={true}
                      type='text'
                      placeholder=''
                      value={
                        /^[0-9]{4}-/.test(job?.jobNumber) // Check if jobNumber is in the format "0275-0001"
                          ? job?.jobNumber // Use jobNumber as it is
                          : `${job?.user?.accountNumber || ''}-${
                              job?.jobNumber || ''
                            }` // Concatenate accountNumber and jobNumber
                      }
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.jobNumber}
                      name='jobNumber'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Multiple Positions Available? */}
              <Form.Item
                label='Multiple Positions Available?'
                name='multiPosition'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please choose a position' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      defaultValue={job?.multiPosition ? 'Yes' : 'No'}
                      placeholder='Choose one'
                      suffixIcon={<DropDownIcon />}
                      onChange={(value) => {
                        setJob({ ...job, multiPosition: value === 'true' });
                      }}
                    >
                      <Option value='false'>No</Option>
                      <Option value='true'>Yes</Option>
                    </Select>
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.multiPosition ?? false}
                      name='multiPosition'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Job Description */}
              <Form.Item label='Job Description'>
                <Row>
                  <Col span={23}>
                    <CKEditor
                      data={job?.discription}
                      editor={ClassicEditor}
                      onReady={(editor) => {}}
                      onChange={handleCKEditorChange1}
                      config={{
                        toolbar: {
                          items: [
                            'bold',
                            'italic',
                            '|',
                            'bulletedList',
                            'numberedList',
                          ],
                          shouldNotGroupWhenFull: true,
                        },
                      }}
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.discription ?? false}
                      name='discription'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Education and Experience Requirements */}
              <Form.Item label='Education and Experience Requirements'>
                <Row>
                  <Col span={23}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={job?.educationAndExperience}
                      onReady={(editor) => {}}
                      onChange={handleCKEditorChange2}
                      config={{
                        toolbar: {
                          items: [
                            'bold',
                            'italic',
                            '|',
                            'bulletedList',
                            'numberedList',
                          ],
                          shouldNotGroupWhenFull: true,
                        },
                      }}
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.educationAndExperience ?? false}
                      name='educationAndExperience'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Special Skills Requirements */}
              <Form.Item
                label='Special Skills Requirements'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please put your skills requirements' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.specialSkills}
                      onChange={(e) =>
                        setJob((job: any) => ({
                          ...job,
                          specialSkills: e.target.value,
                        }))
                      }
                      placeholder=''
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.specialSkills ?? false}
                      name='specialSkills'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Travel Requirements */}
              <Form.Item
                label='Travel Requirements'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please put your Travel requirements' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.travelRequirements}
                      onChange={(e) =>
                        setJob((job: any) => ({
                          ...job,
                          travelRequirements: e.target.value,
                        }))
                      }
                      placeholder=''
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.travelRequirements ?? false}
                      name='travelRequirements'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Telecommuting/Remote Work Permitted? */}
              <Form.Item
                label='Telecommuting/Remote Work Permitted?'
                name='telecommuting'
                rules={[
                  {
                    required: job?.telecommuting === undefined ? true : false,
                    message: (
                      <ErrorMessage message='Please choose a telecommuting/remote work permitted' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      placeholder='Choose one'
                      suffixIcon={<DropDownIcon />}
                      onChange={(value) => {
                        setJob({ ...job, telecommuting: value });
                      }}
                      value={
                        job?.telecommuting
                          ? 'Yes'
                          : job?.telecommuting === undefined
                          ? ''
                          : 'No'
                      }
                      // onChange={handleCKEditorChange2}
                    >
                      <Option value={false}>No</Option>
                      <Option value={true}>Yes</Option>
                    </Select>
                  </Col>
                  <Col span={1}>
                    <Col span={1}>
                      <Checkbox
                        checked={checkedItems?.telecommuting ?? false}
                        name='telecommuting'
                        onChange={handleCheckboxChange}
                      />
                    </Col>
                  </Col>
                </Row>
              </Form.Item>
              {/* Telecommuting/Remote Work Conditions */}
              {job?.telecommuting ? (
                <Form.Item
                  label='Telecommuting/Remote Work Conditions'
                  name='telecommutingCondition'
                  rules={[
                    {
                      required: job?.telecommuting
                        ? job?.telecommutingCondition
                          ? false
                          : true
                        : false,
                      message: (
                        <ErrorMessage message='Please input Telecommuting/Remote Work Conditions' />
                      ),
                    },
                  ]}
                >
                  <Row>
                    <Col span={23}>
                      <Input
                        defaultValue={job?.telecommutingCondition}
                        value={job?.telecommutingCondition}
                        onChange={(e) => {
                          setJob({
                            ...job,
                            telecommutingCondition: e.target.value,
                          });
                        }}
                        placeholder='e.g., 2-3 days per week, reside in the same metro area, etc.'
                      />
                    </Col>
                  </Row>
                </Form.Item>
              ) : (
                ''
              )}
              {/* Salary */}
              <Form.Item
                label='Salary'
                name='salary'
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message='Please put salary' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='text'
                      value={job?.salary ? job?.salary : ''}
                      onChange={(e) =>
                        setJob({ ...job, salary: e.target.value })
                      }
                      placeholder={
                        !job?.salary || job?.salary == 0
                          ? 'Not Provided'
                          : job?.salary
                      }
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.salary ?? false}
                      name='salary'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Name of Company */}
              <Form.Item
                label='Name of Company'
                name='companyName'
                rules={[
                  {
                    required: job?.employerInfo?.companyName ? false : true,
                    message: (
                      <ErrorMessage message='Please Put Name of company' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.employerInfo?.companyName}
                      onChange={(e) =>
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            companyName: e.target.value,
                          },
                        })
                      }
                      placeholder='Jade Century Properties Inc.'
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.companyName ?? false}
                      name='companyName'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Number of Employees */}
              <Form.Item
                label='Number of Employees'
                name='noOfEmployee'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please choose Number of Employees' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      placeholder='Choose one'
                      suffixIcon={<DropDownIcon />}
                      onChange={(value) => {
                        form.setFieldsValue({ noOfEmployee: value });
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            noOfEmployee: value,
                          },
                        });
                      }}
                      value={job?.employerInfo?.noOfEmployee}
                    >
                      <Option value='1 to 3'>1-3</Option>
                      <Option value='12 to 15'>4-14</Option>
                      <Option value='16 to 49'>16-49</Option>
                      <Option value='50'>50+</Option>
                    </Select>
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.noOfEmployee ?? false}
                      name='noOfEmployee'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* First Name of Hiring Manager */}
              <Form.Item
                label='First Name of Hiring Manager'
                name='recruiterFirstName'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please put First Name of Hiring Manager' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.employerInfo?.recruiterFirstName}
                      name='recruiterFirstName'
                      onChange={(e) => {
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            recruiterFirstName: e.target.value,
                          },
                        });
                      }}
                      placeholder=''
                    />
                  </Col>
                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.recruiterFirstName ?? false}
                      name='recruiterFirstName'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Last Name of Hiring Manager */}
              <Form.Item
                label='Last Name of Hiring Manager'
                name='recruiterLastName'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please put Last Name of Hiring Manager' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      value={job?.employerInfo?.recruiterLastName}
                      onChange={(e) => {
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            recruiterLastName: e.target.value,
                          },
                        });
                      }}
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Title of Hiring Manager */}
              <Form.Item
                label='Title of Hiring Manager'
                name='hiringManagerTitle'
                rules={[
                  {
                    required:
                      (job?.employerInfo?.recruiterFirstName ||
                        job?.employerInfo?.recruiterLastName) &&
                      !job?.employerInfo?.hiringManagerTitle
                        ? true
                        : false,
                    message: (
                      <ErrorMessage message='Please Indicate the Hiring Manager’s Title' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Select
                      placeholder='Choose one'
                      suffixIcon={<DropDownIcon />}
                      onChange={(value) => {
                        form.setFieldsValue({ hiringManagerTitle: value });
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            hiringManagerTitle: value,
                          },
                        });
                        if (value !== 'Other') {
                          setManagerTitle(false);
                        } else {
                          setManagerTitle(true);
                        }
                      }}
                      value={
                        job?.employerInfo?.hiringManagerTitle ||
                        job?.employerInfo?.hiringManagerTitle == ''
                          ? job?.employerInfo?.hiringManagerTitle
                          : job?.employerInfo?.hiringManagerTitle
                      }
                    >
                      <Option value='Mr.'>Mr.</Option>
                      <Option value='Mrs.'>Mrs.</Option>
                      <Option value='Ms.'>Ms.</Option>
                      <Option value='Dr.'>Dr.</Option>
                      <Option value='Other'>Other</Option>
                    </Select>
                  </Col>
                </Row>
              </Form.Item>
              {managerTitle ? (
                <Form.Item
                  label=''
                  name=''
                  rules={[
                    {
                      required: false,
                      message: (
                        <ErrorMessage message='Please enter manager title' />
                      ),
                    },
                  ]}
                >
                  <Row>
                    <Col span={23}>
                      <Input
                        onChange={(e) => {
                          setManagerTitleName(e.target.value);
                        }}
                        value={managerTitleName}
                        placeholder='Other manager title'
                      />
                    </Col>

                    <Col span={1}>
                      <Checkbox
                        checked={checkedItems?.companyNature ?? false}
                        name='companyNature'
                        onChange={handleCheckboxChange}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              ) : (
                ''
              )}
              {/* Nature of Company’s Business */}
              <Form.Item
                label='Nature of Company’s Business'
                name='companyNature'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please put Nature of Company’s Business' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      maxLength={55}
                      onChange={(e) => {
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            companyNature: e.target.value,
                          },
                        });
                      }}
                      value={job?.employerInfo?.companyNature}
                      placeholder='Maximum 55 characters'
                    />
                  </Col>

                  <Col span={1}>
                    <Checkbox
                      checked={checkedItems?.companyNature ?? false}
                      name='companyNature'
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Job Worksite Street Address */}
              <Col span={23}>
                <Form.Item
                  label='City'
                  name='city'
                  rules={[
                    {
                      required: job?.employerInfo?.city ? false : true,
                      message: (
                        <ErrorMessage message='Please input your city' />
                      ),
                    },
                  ]}
                >
                  <Input
                    defaultValue={job?.employerInfo?.city}
                    value={job?.employerInfo?.city}
                    onChange={(e: any) => {
                      setJob({
                        ...job,
                        employerInfo: {
                          ...job?.employerInfo,
                          city: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={23}>
                <Form.Item
                  label='State'
                  name='state'
                  rules={[
                    {
                      required: job?.employerInfo?.state ? false : true,
                      message: (
                        <ErrorMessage message='Please input your state' />
                      ),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    defaultValue={job.employerInfo?.state}
                    value={job.employerInfo?.state}
                    onChange={(value) => {
                      setJob({
                        ...job,
                        employerInfo: {
                          ...job?.employerInfo,
                          state: value,
                        },
                      });
                    }}
                  >
                    {locations.map((location) => (
                      <Option
                        key={location.key}
                        value={location.label}
                        label={location.label}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '13px',
                          }}
                        >
                          {location.label}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* Job Worksite Zip Code */}
              <Form.Item
                label='Job Worksite Zip Code'
                name='worksiteZipCode'
                rules={[
                  {
                    required: job?.employerInfo?.worksiteZipCode ? false : true,
                    message: (
                      <ErrorMessage message='Please input your job worksite zip code' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='number'
                      onChange={(e) => {
                        setJob({
                          ...job,
                          employerInfo: {
                            ...job?.employerInfo,
                            worksiteZipCode: e.target.value,
                          },
                        });
                      }}
                      value={job?.employerInfo?.worksiteZipCode}
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Special Instructions */}
              <Form.Item label='Special Instructions'>
                <div>
                  <Row>
                    <Col span={23}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={job?.specialInstructions}
                        onReady={(editor) => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data);
                          setJob({
                            ...job,
                            specialInstructions: data,
                          });
                        }}
                        config={{
                          toolbar: {
                            items: [
                              'bold',
                              'italic',
                              '|', // Add a separator after the second button
                              'bulletedList',
                              'numberedList',
                            ],
                            shouldNotGroupWhenFull: true, // Add this to prevent buttons from grouping
                          },
                        }}
                      />
                    </Col>
                    <Col span={1}>
                      <Checkbox
                        checked={checkedItems?.specialInstructions ?? false}
                        name='specialInstructions'
                        onChange={handleCheckboxChange}
                      />
                    </Col>
                  </Row>
                </div>
              </Form.Item>
              {/* Your Internal Reference Number/Code */}
              <Form.Item
                label='Your Internal Reference Number/Code'
                name='instructions'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input your special instructions' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='text'
                      onChange={(e) =>
                        setJob((job: any) => ({
                          ...job,
                          referenceCode: e.target.value, // Update toApplyStatus
                        }))
                      }
                      value={job?.referenceCode}
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Law Firm/Consultant Associated with this Recruitment */}
              <Form.Item
                label='Law Firm/Consultant Associated with this Recruitment'
                name='law_firm'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input your law firm' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      onChange={(e) =>
                        setJob((job: any) => ({
                          ...job,
                          recruitmentFirm: e.target.value, // Update toApplyStatus
                        }))
                      }
                      value={job?.recruitmentFirm}
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* agent */}
              <Row>
                <Col
                  span={23}
                  className='agent'
                >
                  <Form.Item
                    label='Agent'
                    name='employerCompanyName'
                    rules={[
                      {
                        required: true,
                        message: (
                          <ErrorMessage message='Please input agent name' />
                        ),
                      },
                    ]}
                  >
                    <Input
                      value={accountHolderData?.companyName}
                      onChange={(e) => {
                        setAccountHolderData((prev: any) => ({
                          ...prev,
                          companyName: e.target.value,
                        }));
                      }}
                      placeholder=''
                    />
                  </Form.Item>
                  {/* Contact Person Fisrt Name */}
                  <Form.Item
                    label='Contact Person Name'
                    name='firstName'
                    rules={[
                      {
                        required: true,
                        message: (
                          <ErrorMessage message='Please input contact person name' />
                        ),
                      },
                    ]}
                  >
                    <Input
                      defaultValue={accountHolderData?.firstName}
                      value={accountHolderData?.firstName}
                      onChange={(e) => {
                        setAccountHolderData((prev: any) => ({
                          ...prev,
                          firstName: e.target.value,
                        }));
                      }}
                      placeholder=''
                    />
                  </Form.Item>
                  {/* Agent Address */}
                  <Form.Item
                    label='Agent Address'
                    name='address'
                    rules={[
                      {
                        required: true,
                        message: (
                          <ErrorMessage message='Please input agent address' />
                        ),
                      },
                    ]}
                  >
                    <Input
                      defaultValue={accountHolderData?.address}
                      value={accountHolderData?.address}
                      placeholder=''
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setAccountHolderData((prevJob: any) => ({
                          ...prevJob,
                          address: value,
                        }));
                      }}
                    />
                  </Form.Item>
                  {/* Agent  City  */}
                  <Form.Item
                    label='City'
                    name='employerCity'
                    rules={[
                      {
                        required: true,
                        message: (
                          <ErrorMessage message='Please input your city' />
                        ),
                      },
                    ]}
                  >
                    <Input
                      defaultValue={accountHolderData?.city}
                      value={accountHolderData?.city}
                      onChange={(e) => {
                        setAccountHolderData((prev: any) => ({
                          ...prev,
                          city: e.target.value,
                        }));
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label='State'
                    name='employerState'
                    rules={[
                      {
                        required: true,
                        message: (
                          <ErrorMessage message='Please input your state' />
                        ),
                      },
                    ]}
                  >
                    <Select
                      onChange={(value) => {
                        setAccountHolderData((prev: any) => ({
                          ...prev,
                          state: value,
                        }));
                      }}
                      defaultValue={accountHolderData?.state}
                      value={accountHolderData?.state}
                      showSearch
                    >
                      {locations.map((location) => (
                        <Option
                          key={location.key}
                          value={location.label}
                          label={location.label}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '13px',
                            }}
                          >
                            {/* <LocationIcon /> */}
                            {location.label}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* Agent Zip Code */}

                  <Form.Item
                    label='Agent Zip Code'
                    name='employerZipCode'
                    rules={[
                      {
                        required: true,
                        message: (
                          <ErrorMessage message='Please input agent zip code' />
                        ),
                      },
                    ]}
                  >
                    <Input
                      type='text'
                      value={
                        accountHolderData?.zipCode == 'undefined' ||
                        accountHolderData?.zipCode == undefined
                          ? ''
                          : accountHolderData?.zipCode
                      }
                      onChange={(e) => {
                        setAccountHolderData((prev: any) => ({
                          ...prev,
                          zipCode: e.target.value,
                        }));
                      }}
                      placeholder=''
                    />
                  </Form.Item>
                  {/* Contact Phone */}

                  <Form.Item
                    label='Contact Phone'
                    name='phone'
                    rules={[
                      {
                        required: true,
                        message: (
                          <ErrorMessage message='Please input contact phone' />
                        ),
                      },
                    ]}
                  >
                    <Input
                      name='phone'
                      type='text'
                      defaultValue={accountHolderData?.phone}
                      value={accountHolderData?.phone}
                      onChange={(e) => {
                        setAccountHolderData((prev: any) => ({
                          ...prev,
                          phone: e.target.value,
                        }));
                      }}
                      placeholder=''
                    />
                  </Form.Item>
                  {/* Contact Email */}

                  <Form.Item
                    label='Contact Email'
                    name='email'
                    rules={[
                      {
                        required: true,
                        message: (
                          <ErrorMessage message='Please input contact email' />
                        ),
                      },
                    ]}
                  >
                    <Input
                      type='email'
                      name='email'
                      defaultValue={accountHolderData?.email}
                      value={accountHolderData?.email}
                      onChange={(e) => {
                        setAccountHolderData((prev: any) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }}
                      placeholder=''
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Send Email Invoice Copy to */}
              {/* <Form.Item
                label='Send Email Invoice Copy to'
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message='Please input Email' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='email'
                      defaultValue={job?.invoiceCopyTo}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setAgentData((agentsData) => ({
                          ...agentsData,
                          invoiceCopyTo: value,
                        }));
                      }}
                      name='invoiceCopyTo'
                      placeholder='psusa@gmail.com'
                    />
                  </Col>
                </Row>
              </Form.Item> */}
              {/* Submit Resume to PSUSA */}
              <FormItem
                name='resumeTo_PSUSA'
                rules={[
                  {
                    required: radioCheck == 'checked' ? false : true,
                    message: (
                      <ErrorMessage message='Please select option for submit resume to PSUSA' />
                    ),
                  },
                ]}
              >
                <div>
                  <label className='custom-label-psusa'>
                    Submit Resume to PSUSA
                  </label>
                </div>
                <Row>
                  <Col span={23}>
                    <Radio.Group
                      onChange={onChange}
                      value={job?.resumeTo_PSUSA}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </FormItem>
              {/* Submit Resume to Employer */}
              <Form.Item
                label='Submit Resume to Employer'
                name='submitResume'
                rules={[
                  {
                    required: mail && resumeCkeck,
                    message: (
                      <ErrorMessage message='Please fill this input field.' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='text'
                      defaultValue={job?.submitResume}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        if (value) {
                          setJob((job: any) => ({
                            ...job,
                            submitResume: value,
                          }));
                          setResumeCheck(false);
                        } else {
                          setJob((job: any) => ({
                            ...job,
                            submitResume: undefined,
                          }));
                          setResumeCheck(true);
                        }
                      }}
                      name='submitResume'
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Status and Display */}
              <h3 className='stats-and-display'>Status and Display</h3>
              {/* Recruitment Start Date */}
              <Form.Item
                label='Recruitment Start Date'
                name='startDate'
                rules={[
                  {
                    required: job.startDate ? false : true,
                    message: (
                      <ErrorMessage message='Please input Recruitment Start Date' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <DatePicker
                      style={{ width: '100%' }}
                      allowClear={false}
                      suffixIcon={<CalenderIcon />}
                      placeholder='01-02-2024'
                      format='MM-DD-YYYY'
                      defaultValue={
                        job?.startDate ? dayjs(job.startDate) : null
                      }
                      disabledDate={(current) =>
                        current && current < dayjs().startOf('day')
                      }
                      onChange={(selectedDate) => {
                        if (job?.jobDuration !== undefined) {
                          setJob({
                            ...job,
                            endDate: calculateEndDate(
                              selectedDate,
                              job?.jobDuration
                            ),
                            startDate: selectedDate
                              ? selectedDate.format('YYYY-MM-DD')
                              : null,
                          });
                        } else {
                          setJob({
                            ...job,
                            startDate: selectedDate
                              ? selectedDate.format('YYYY-MM-DD')
                              : null,
                          });
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Duration of Recruitment */}
              <Form.Item
                label='Duration of Recruitment'
                name='jobDuration'
                rules={[
                  {
                    required: true,
                    message: (
                      <ErrorMessage message='Please input duration of recruitment ' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <Input
                      type='number'
                      defaultValue={job?.jobDuration}
                      onChange={async (e) => {
                        const { name, value } = e.target;
                        if (job?.startDate) {
                          const endDateNow = await calculateEndDate(
                            job?.startDate,
                            value
                          );
                          setJob({
                            ...job,
                            endDate: endDateNow,
                            jobDuration: value,
                          });
                        } else {
                          setJob({
                            ...job,
                            jobDuration: value,
                          });
                        }
                      }}
                      placeholder=''
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* End Date */}
              <Form.Item
                label='End Date'
                name='endDate'
                rules={[
                  {
                    required: false,
                    message: (
                      <ErrorMessage message='Please input Recruitment End Date' />
                    ),
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <DatePicker
                      value={job?.endDate && dayjs(job.endDate)}
                      // defaultValue={job.startDate}
                      style={{ width: '100%' }}
                      disabled={true}
                      allowClear={false}
                      suffixIcon={<CalenderIcon />}
                      placeholder='01-02-2024'
                      format='MM-DD-YYYY'
                      defaultValue={job?.endDate && dayjs(job.endDate)}
                      onChange={(selectedDate) => {
                        setJob({
                          ...job,
                          endDate: selectedDate
                            ? selectedDate.format('YYYY-MM-DD')
                            : null,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* Status */}
              <FormItem>
                <div>
                  <label className='custom-label-psusa'>Status</label>
                </div>
                <Row>
                  <Col span={23}>
                    <Radio.Group
                      defaultValue={
                        job?.PSUSA_status == 'Enabled'
                          ? 1
                          : job?.PSUSA_status == 'Disabled'
                          ? 2
                          : 0
                      }
                      onChange={(e) =>
                        setAgentData({
                          ...agentsData,
                          PSUSA_status:
                            e.target.value == 1 ? 'Enabled' : 'Disabled',
                        })
                      }
                    >
                      <Radio value={1}>Enabled</Radio>
                      <Radio value={2}>Disabled</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </FormItem>
              {/* Store Date */}
              <Form.Item
                label='Store Date'
                name='storeDate'
                rules={[
                  {
                    required: false,
                    message: <ErrorMessage message='Please input Store Date' />,
                  },
                ]}
              >
                <Row>
                  <Col span={23}>
                    <DatePicker
                      style={{ width: '100%' }}
                      allowClear={false}
                      suffixIcon={<CalenderIcon />}
                      placeholder='01-02-2024'
                      format='MM-DD-YYYY'
                      defaultValue={
                        job?.storeDate ? dayjs(job.storeDate) : dayjs()
                      }
                      onChange={(selectedDate) => {
                        setAgentData({
                          ...agentsData,
                          storeDate: selectedDate.format('YYYY-MM-DD'),
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
              {/* save */}
              <Button
                loading={loading}
                type='primary'
                htmlType='submit'
              >
                Save
              </Button>
              <Button
                style={{ position: 'relative', left: '5px' }}
                onClick={() => Discard()}
                className='search-butn'
                htmlType='submit'
              >
                Cancel
              </Button>
            </Form>
          </FormWraper>
        </Col>
      </Row>
    </>
  );
};

export default ApplicationDetails;
