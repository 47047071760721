import StatusFailed from 'src/Assets/Icons/StatusFailed';
import StatusSuccess from 'src/Assets/Icons/StatusSuccess';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const data = [
  {
    'Store Date': '2023-12-15',
    'Job Number': '011233 (46724)',
    'Employer Name': 'Hispanic Business Solutions...',
    'Position Title': 'Project Management Specialist',
    'Agent Name': 'Jade Century Properties Inc.',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '604079 (46741)',
    'Employer Name': 'Jade Century Properties Inc.',
    'Position Title': 'Financial Manager',
    'Agent Name': 'Jade Century Properties Inc.',
    Status: false,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '294289 (46736)',
    'Employer Name': 'Art Partner, Inc.',
    'Position Title': 'Accounting Manager',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '294288 (46735)',
    'Employer Name': 'G.C. Consultant, Inc.',
    'Position Title': 'Auditor',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '0251127 (46734)',
    'Employer Name': 'Cigna-Evernorth Services Inc.',
    'Position Title': 'Application Development Senior Manager',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '0251126 (46733)',
    'Employer Name': 'Christiana Care Health Services...',
    'Position Title': 'Senior Business Intelligence Analyst',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131825 (46731)',
    'Employer Name': 'Southeastern Physician Services...',
    'Position Title': 'Hospitalist',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131824 (46731)',
    'Employer Name': 'Certified Anesthesia Services...',
    'Position Title': 'Anesthesiologist',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: false,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131823 (46730)',
    'Employer Name': 'Northeast Arkansas Clinic...',
    'Position Title': 'Pain Management Physician',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '00251125 (46729)',
    'Employer Name': 'Firmenich Incorporated',
    'Position Title': 'Associate Scientist',
    'Agent Name': 'Landau, Hess, Simon & Choi',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '015508 (46727)',
    'Employer Name': 'The Flight School of Gwinnett....',
    'Position Title': 'Flight Instructor',
    'Agent Name': 'Law offices of Yong Men, P.C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '0251124 (46725)',
    'Employer Name': 'Cigna-Evernorth Services Inc.',
    'Position Title': 'Software Engineering Advisor',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '2703214 (46724)',
    'Employer Name': 'Motional AD Inc.',
    'Position Title': 'Senior Engineer',
    'Agent Name': 'Fragomen, Del Rey, Bernsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '093806 (46723)',
    'Employer Name': 'Tera Nix DDS PA',
    'Position Title': 'Associate Dentist',
    'Agent Name': 'Constangy, Books Smith, & Prop',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '2703213 (46722)',
    'Employer Name': 'Simplisafe, Inc.',
    'Position Title': 'Senior Data Scientist',
    'Agent Name': 'Fragment, Del Rey, Bemsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '604078 (46721)',
    'Employer Name': 'Arwen Nicolas Inc.',
    'Position Title': 'Head of Sales & Marketing',
    'Agent Name': 'Law Office of Jiali Pan',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '588031 (46720)',
    'Employer Name': 'VistaTech LLC d/b/a FlyXO',
    'Position Title': 'Senior Data Engineer',
    'Agent Name': 'Eisenberg Law Firm PLLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '588030 (46719)',
    'Employer Name': 'VistaTech LLC d/b/a FlyXO',
    'Position Title': 'Data Analyst, Aviation',
    'Agent Name': 'Eisenberg Law Firm PLLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '572233 (46728)',
    'Employer Name': 'Taad Group, Inc.',
    'Position Title': 'Management Analyst',
    'Agent Name': 'Law Offices of Gary J. Kim',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '0041275 (46718)',
    'Employer Name': 'Sang Eun Lee DDS Inc.',
    'Position Title': 'Dentist',
    'Agent Name': 'Law Offices of Jim Kahng',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '588029 (46717)',
    'Employer Name': 'Upway USA Inc.',
    'Position Title': 'B2B Sales Development Lead',
    'Agent Name': 'Eisenberg Law Firm PPLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '294287 (46715)',
    'Employer Name': 'Zonin USA Inc.',
    'Position Title': 'Managing Director',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '2703212 (46714)',
    'Employer Name': 'Bracebridge Capital, LLC',
    'Position Title': 'Application Development System Analyst II',
    'Agent Name': 'Fragomen, Del Rey, Bemsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '668094 (46716)',
    'Employer Name': 'Verition Group, LLC',
    'Position Title': 'Risk Developer',
    'Agent Name': 'Grossman Young & Hammond LLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-11',
    'Job Number': '2312010 (46713)',
    'Employer Name': 'Novozymes North America, Inc.',
    'Position Title': 'Associate Category Manager',
    'Agent Name': 'Ogletree Deakins',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '011233 (46724)',
    'Employer Name': 'Hispanic Business Solutions...',
    'Position Title': 'Project Management Specialist',
    'Agent Name': 'Jade Century Properties Inc.',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '604079 (46741)',
    'Employer Name': 'Jade Century Properties Inc.',
    'Position Title': 'Financial Manager',
    'Agent Name': 'Jade Century Properties Inc.',
    Status: false,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '294289 (46736)',
    'Employer Name': 'Art Partner, Inc.',
    'Position Title': 'Accounting Manager',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '294288 (46735)',
    'Employer Name': 'G.C. Consultant, Inc.',
    'Position Title': 'Auditor',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '0251127 (46734)',
    'Employer Name': 'Cigna-Evernorth Services Inc.',
    'Position Title': 'Application Development Senior Manager',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '0251126 (46733)',
    'Employer Name': 'Christiana Care Health Services...',
    'Position Title': 'Senior Business Intelligence Analyst',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131825 (46731)',
    'Employer Name': 'Southeastern Physician Services...',
    'Position Title': 'Hospitalist',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131824 (46731)',
    'Employer Name': 'Certified Anesthesia Services...',
    'Position Title': 'Anesthesiologist',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: false,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131823 (46730)',
    'Employer Name': 'Northeast Arkansas Clinic...',
    'Position Title': 'Pain Management Physician',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '00251125 (46729)',
    'Employer Name': 'Firmenich Incorporated',
    'Position Title': 'Associate Scientist',
    'Agent Name': 'Landau, Hess, Simon & Choi',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '015508 (46727)',
    'Employer Name': 'The Flight School of Gwinnett....',
    'Position Title': 'Flight Instructor',
    'Agent Name': 'Law offices of Yong Men, P.C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '0251124 (46725)',
    'Employer Name': 'Cigna-Evernorth Services Inc.',
    'Position Title': 'Software Engineering Advisor',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '2703214 (46724)',
    'Employer Name': 'Motional AD Inc.',
    'Position Title': 'Senior Engineer',
    'Agent Name': 'Fragomen, Del Rey, Bernsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '093806 (46723)',
    'Employer Name': 'Tera Nix DDS PA',
    'Position Title': 'Associate Dentist',
    'Agent Name': 'Constangy, Books Smith, & Prop',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '2703213 (46722)',
    'Employer Name': 'Simplisafe, Inc.',
    'Position Title': 'Senior Data Scientist',
    'Agent Name': 'Fragment, Del Rey, Bemsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '604078 (46721)',
    'Employer Name': 'Arwen Nicolas Inc.',
    'Position Title': 'Head of Sales & Marketing',
    'Agent Name': 'Law Office of Jiali Pan',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '588031 (46720)',
    'Employer Name': 'VistaTech LLC d/b/a FlyXO',
    'Position Title': 'Senior Data Engineer',
    'Agent Name': 'Eisenberg Law Firm PLLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '588030 (46719)',
    'Employer Name': 'VistaTech LLC d/b/a FlyXO',
    'Position Title': 'Data Analyst, Aviation',
    'Agent Name': 'Eisenberg Law Firm PLLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '572233 (46728)',
    'Employer Name': 'Taad Group, Inc.',
    'Position Title': 'Management Analyst',
    'Agent Name': 'Law Offices of Gary J. Kim',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '0041275 (46718)',
    'Employer Name': 'Sang Eun Lee DDS Inc.',
    'Position Title': 'Dentist',
    'Agent Name': 'Law Offices of Jim Kahng',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '588029 (46717)',
    'Employer Name': 'Upway USA Inc.',
    'Position Title': 'B2B Sales Development Lead',
    'Agent Name': 'Eisenberg Law Firm PPLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '294287 (46715)',
    'Employer Name': 'Zonin USA Inc.',
    'Position Title': 'Managing Director',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '2703212 (46714)',
    'Employer Name': 'Bracebridge Capital, LLC',
    'Position Title': 'Application Development System Analyst II',
    'Agent Name': 'Fragomen, Del Rey, Bemsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '668094 (46716)',
    'Employer Name': 'Verition Group, LLC',
    'Position Title': 'Risk Developer',
    'Agent Name': 'Grossman Young & Hammond LLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-11',
    'Job Number': '2312010 (46713)',
    'Employer Name': 'Novozymes North America, Inc.',
    'Position Title': 'Associate Category Manager',
    'Agent Name': 'Ogletree Deakins',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '011233 (46724)',
    'Employer Name': 'Hispanic Business Solutions...',
    'Position Title': 'Project Management Specialist',
    'Agent Name': 'Jade Century Properties Inc.',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '604079 (46741)',
    'Employer Name': 'Jade Century Properties Inc.',
    'Position Title': 'Financial Manager',
    'Agent Name': 'Jade Century Properties Inc.',
    Status: false,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '294289 (46736)',
    'Employer Name': 'Art Partner, Inc.',
    'Position Title': 'Accounting Manager',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '294288 (46735)',
    'Employer Name': 'G.C. Consultant, Inc.',
    'Position Title': 'Auditor',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '0251127 (46734)',
    'Employer Name': 'Cigna-Evernorth Services Inc.',
    'Position Title': 'Application Development Senior Manager',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '0251126 (46733)',
    'Employer Name': 'Christiana Care Health Services...',
    'Position Title': 'Senior Business Intelligence Analyst',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131825 (46731)',
    'Employer Name': 'Southeastern Physician Services...',
    'Position Title': 'Hospitalist',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131824 (46731)',
    'Employer Name': 'Certified Anesthesia Services...',
    'Position Title': 'Anesthesiologist',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: false,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131823 (46730)',
    'Employer Name': 'Northeast Arkansas Clinic...',
    'Position Title': 'Pain Management Physician',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '00251125 (46729)',
    'Employer Name': 'Firmenich Incorporated',
    'Position Title': 'Associate Scientist',
    'Agent Name': 'Landau, Hess, Simon & Choi',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '015508 (46727)',
    'Employer Name': 'The Flight School of Gwinnett....',
    'Position Title': 'Flight Instructor',
    'Agent Name': 'Law offices of Yong Men, P.C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '0251124 (46725)',
    'Employer Name': 'Cigna-Evernorth Services Inc.',
    'Position Title': 'Software Engineering Advisor',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '2703214 (46724)',
    'Employer Name': 'Motional AD Inc.',
    'Position Title': 'Senior Engineer',
    'Agent Name': 'Fragomen, Del Rey, Bernsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '093806 (46723)',
    'Employer Name': 'Tera Nix DDS PA',
    'Position Title': 'Associate Dentist',
    'Agent Name': 'Constangy, Books Smith, & Prop',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '2703213 (46722)',
    'Employer Name': 'Simplisafe, Inc.',
    'Position Title': 'Senior Data Scientist',
    'Agent Name': 'Fragment, Del Rey, Bemsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '604078 (46721)',
    'Employer Name': 'Arwen Nicolas Inc.',
    'Position Title': 'Head of Sales & Marketing',
    'Agent Name': 'Law Office of Jiali Pan',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '588031 (46720)',
    'Employer Name': 'VistaTech LLC d/b/a FlyXO',
    'Position Title': 'Senior Data Engineer',
    'Agent Name': 'Eisenberg Law Firm PLLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '588030 (46719)',
    'Employer Name': 'VistaTech LLC d/b/a FlyXO',
    'Position Title': 'Data Analyst, Aviation',
    'Agent Name': 'Eisenberg Law Firm PLLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '572233 (46728)',
    'Employer Name': 'Taad Group, Inc.',
    'Position Title': 'Management Analyst',
    'Agent Name': 'Law Offices of Gary J. Kim',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '0041275 (46718)',
    'Employer Name': 'Sang Eun Lee DDS Inc.',
    'Position Title': 'Dentist',
    'Agent Name': 'Law Offices of Jim Kahng',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '588029 (46717)',
    'Employer Name': 'Upway USA Inc.',
    'Position Title': 'B2B Sales Development Lead',
    'Agent Name': 'Eisenberg Law Firm PPLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '294287 (46715)',
    'Employer Name': 'Zonin USA Inc.',
    'Position Title': 'Managing Director',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '2703212 (46714)',
    'Employer Name': 'Bracebridge Capital, LLC',
    'Position Title': 'Application Development System Analyst II',
    'Agent Name': 'Fragomen, Del Rey, Bemsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '668094 (46716)',
    'Employer Name': 'Verition Group, LLC',
    'Position Title': 'Risk Developer',
    'Agent Name': 'Grossman Young & Hammond LLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-11',
    'Job Number': '2312010 (46713)',
    'Employer Name': 'Novozymes North America, Inc.',
    'Position Title': 'Associate Category Manager',
    'Agent Name': 'Ogletree Deakins',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '011233 (46724)',
    'Employer Name': 'Hispanic Business Solutions...',
    'Position Title': 'Project Management Specialist',
    'Agent Name': 'Jade Century Properties Inc.',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '604079 (46741)',
    'Employer Name': 'Jade Century Properties Inc.',
    'Position Title': 'Financial Manager',
    'Agent Name': 'Jade Century Properties Inc.',
    Status: false,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '294289 (46736)',
    'Employer Name': 'Art Partner, Inc.',
    'Position Title': 'Accounting Manager',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '294288 (46735)',
    'Employer Name': 'G.C. Consultant, Inc.',
    'Position Title': 'Auditor',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '0251127 (46734)',
    'Employer Name': 'Cigna-Evernorth Services Inc.',
    'Position Title': 'Application Development Senior Manager',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-15',
    'Job Number': '0251126 (46733)',
    'Employer Name': 'Christiana Care Health Services...',
    'Position Title': 'Senior Business Intelligence Analyst',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131825 (46731)',
    'Employer Name': 'Southeastern Physician Services...',
    'Position Title': 'Hospitalist',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131824 (46731)',
    'Employer Name': 'Certified Anesthesia Services...',
    'Position Title': 'Anesthesiologist',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: false,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '131823 (46730)',
    'Employer Name': 'Northeast Arkansas Clinic...',
    'Position Title': 'Pain Management Physician',
    'Agent Name': 'Siskind Susser, P,C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-14',
    'Job Number': '00251125 (46729)',
    'Employer Name': 'Firmenich Incorporated',
    'Position Title': 'Associate Scientist',
    'Agent Name': 'Landau, Hess, Simon & Choi',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '015508 (46727)',
    'Employer Name': 'The Flight School of Gwinnett....',
    'Position Title': 'Flight Instructor',
    'Agent Name': 'Law offices of Yong Men, P.C.',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '0251124 (46725)',
    'Employer Name': 'Cigna-Evernorth Services Inc.',
    'Position Title': 'Software Engineering Advisor',
    'Agent Name': 'Landau, Hess, Simon, Choi & Doeb',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '2703214 (46724)',
    'Employer Name': 'Motional AD Inc.',
    'Position Title': 'Senior Engineer',
    'Agent Name': 'Fragomen, Del Rey, Bernsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '093806 (46723)',
    'Employer Name': 'Tera Nix DDS PA',
    'Position Title': 'Associate Dentist',
    'Agent Name': 'Constangy, Books Smith, & Prop',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '2703213 (46722)',
    'Employer Name': 'Simplisafe, Inc.',
    'Position Title': 'Senior Data Scientist',
    'Agent Name': 'Fragment, Del Rey, Bemsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '604078 (46721)',
    'Employer Name': 'Arwen Nicolas Inc.',
    'Position Title': 'Head of Sales & Marketing',
    'Agent Name': 'Law Office of Jiali Pan',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '588031 (46720)',
    'Employer Name': 'VistaTech LLC d/b/a FlyXO',
    'Position Title': 'Senior Data Engineer',
    'Agent Name': 'Eisenberg Law Firm PLLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '588030 (46719)',
    'Employer Name': 'VistaTech LLC d/b/a FlyXO',
    'Position Title': 'Data Analyst, Aviation',
    'Agent Name': 'Eisenberg Law Firm PLLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-13',
    'Job Number': '572233 (46728)',
    'Employer Name': 'Taad Group, Inc.',
    'Position Title': 'Management Analyst',
    'Agent Name': 'Law Offices of Gary J. Kim',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '0041275 (46718)',
    'Employer Name': 'Sang Eun Lee DDS Inc.',
    'Position Title': 'Dentist',
    'Agent Name': 'Law Offices of Jim Kahng',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '588029 (46717)',
    'Employer Name': 'Upway USA Inc.',
    'Position Title': 'B2B Sales Development Lead',
    'Agent Name': 'Eisenberg Law Firm PPLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '294287 (46715)',
    'Employer Name': 'Zonin USA Inc.',
    'Position Title': 'Managing Director',
    'Agent Name': 'Liuzzo & Associates',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '2703212 (46714)',
    'Employer Name': 'Bracebridge Capital, LLC',
    'Position Title': 'Application Development System Analyst II',
    'Agent Name': 'Fragomen, Del Rey, Bemsen & Loe',
    Status: true,
  },
  {
    'Store Date': '2023-12-12',
    'Job Number': '668094 (46716)',
    'Employer Name': 'Verition Group, LLC',
    'Position Title': 'Risk Developer',
    'Agent Name': 'Grossman Young & Hammond LLC',
    Status: true,
  },
  {
    'Store Date': '2023-12-11',
    'Job Number': '2312010 (46713)',
    'Employer Name': 'Novozymes North America, Inc.',
    'Position Title': 'Associate Category Manager',
    'Agent Name': 'Ogletree Deakins',
    Status: true,
  },
];

export const columns = [
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    width: 210,
    sorter: true,
    render: (text: any, object: any) => {
      const inputDate = new Date("1980-06-15");
      const referenceDate = new Date(object.startDate);
      if(inputDate<referenceDate){
      const momentObj: any = moment(object.endDate);
      const date: any = moment(object.startDate).format('MM-DD-YYYY');
      let colorText = '';
      if (
        (object.PSUSA_status === '' || object.PSUSA_status === null) &&
        (object.jobFrom === '' || object.jobFrom === null)
      ) {
        colorText = 'red';
      } else if (
        object.PSUSA_status === 'Disabled' &&
        object?.jobFrom == 'Admin Panel'
      ) {
        colorText = 'black';
      } else if (momentObj.isBefore(moment().startOf('day'), 'day')) {
        colorText = '#34CB61';
      }

      return <div style={{ color: colorText }}>{date}</div>;
    }
    return null;
    },
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    width: 210,
    sorter: true,
    render: (text: any, object: any) => {

     
      if(object.endDate !== '1899-11-30'){
        const momentObj: any = moment(object.endDate);
        const date: any = moment(object.endDate).format('MM-DD-YYYY'); // Convert backend date string to Moment object
        const currentDate = moment();
        let colorText = '';
        if (
          (object.PSUSA_status === '' || object.PSUSA_status === null) &&
          (object.jobFrom === '' || object.jobFrom === null)
        ) {
          colorText = 'red';
        } else if (
          object.PSUSA_status === 'Disabled' &&
          object?.jobFrom == 'Admin Panel'
        ) {
          colorText = 'black';
        } else if (momentObj.isBefore(moment().startOf('day'), 'day')) {
          colorText = '#34CB61';
        }
  
        return <div style={{ color: colorText }}>{date}</div>;
      }
     return null;
    },
  },
  {
    title: 'Store Date',
    dataIndex: 'storeDate',
    key: 'storeDate',
    width: 210,
    sorter: true,
    render: (text: any, object: any) => {
      const momentObj = moment(object.endDate);
      const date: any = moment(object.storeDate).format('MM-DD-YYYY'); // Convert backend date string to Moment object
      const currentDate = moment(); // Get the current date and time
      let colorText = '';
      if (
        (object.PSUSA_status === '' || object.PSUSA_status === null) &&
        (object.jobFrom === '' || object.jobFrom === null)
      ) {
        colorText = 'red';
      } else if (
        object.PSUSA_status === 'Disabled' &&
        object?.jobFrom == 'Admin Panel'
      ) {
        colorText = 'black';
      } else if (momentObj.isBefore(moment().startOf('day'), 'day')) {
        colorText = '#34CB61';
      }

      return <div style={{ color: colorText }}> {date}</div>;
    },
  },
  {
    title: 'Job Number',
    dataIndex: 'jobNumber',
    key: 'jobNumber',
    width: 181,
    sorter: true,
    render: (text: any, object: any) => {
      let jobNum: any;
      if (!object || object.jobNumber === undefined) {
        jobNum = '';
    } else if (object.jobNumber == 0) {
        jobNum = '';
    } else if (object?.jobNumber?.includes("-")) {
        jobNum = object.jobNumber;
    } else {
        jobNum = `${object?.user?.accountNumber}-${object['jobNumber']}`;
    }

      const momentObj = moment(object.endDate); // Convert backend date string to Moment object
      const currentDate = moment();
      let colorText = '';
      if (
        (object.PSUSA_status === '' || object.PSUSA_status === null) &&
        (object.jobFrom === '' || object.jobFrom === null)
      ) {
        colorText = 'red';
      } else if (
        object.PSUSA_status === 'Disabled' &&
        object?.jobFrom == 'Admin Panel'
      ) {
        colorText = 'black';
      } else if (momentObj.isBefore(moment().startOf('day'), 'day')) {
        colorText = '#34CB61';
      }

      return <a style={{ color: colorText ? colorText : 'black' }}>{jobNum}</a>;
    },
  },
  {
    title: 'Employer Name',
    dataIndex: 'companyName',
    key: 'companyName',
    width: 294,
    sorter: true,
    render: (text: any, object: any) => {
      const momentObj = moment(object.endDate); // Convert backend date string to Moment object
      const currentDate = moment();
      let colorText = '';
      if (
        (object.PSUSA_status === '' || object.PSUSA_status === null) &&
        (object.jobFrom === '' || object.jobFrom === null)
      ) {
        colorText = 'red';
      } else if (
        object.PSUSA_status === 'Disabled' &&
        object?.jobFrom == 'Admin Panel'
      ) {
        colorText = 'black';
      } else if (momentObj.isBefore(moment().startOf('day'), 'day')) {
        colorText = '#34CB61';
      }

      return (
        <div style={{ color: colorText }}>
          {' '}
          {object.employerInfo && object.employerInfo.companyName}
        </div>
      );
    },
  },
  {
    title: 'Position Title',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    width: 338,
    sorter: true,
    render: (text: any, object: any) => {
      const momentObj = moment(object.endDate); // Convert backend date string to Moment object
      const currentDate = moment();
      let colorText = '';
      if (
        (object.PSUSA_status === '' || object.PSUSA_status === null) &&
        (object.jobFrom === '' || object.jobFrom === null)
      ) {
        colorText = 'red';
      } else if (
        object.PSUSA_status === 'Disabled' &&
        object?.jobFrom == 'Admin Panel'
      ) {
        colorText = 'black';
      } else if (momentObj.isBefore(moment().startOf('day'), 'day')) {
        colorText = '#34CB61';
      }

      return <div style={{ color: colorText }}> {object.jobTitle}</div>;
    },
  },
  {
    title: 'Agent Name',
    dataIndex: 'Agent Name',
    key: 'firstName',
    width: 294,
    sorter: true,
    render: (text: any, object: any) => {
      const momentObj = moment(object.endDate); // Convert backend date string to Moment object
      const currentDate = moment();
      let colorText = '';
      if (
        (object.PSUSA_status === '' || object.PSUSA_status === null) &&
        (object.jobFrom === '' || object.jobFrom === null)
      ) {
        colorText = 'red';
      } else if (
        object.PSUSA_status === 'Disabled' &&
        object?.jobFrom == 'Admin Panel'
      ) {
        colorText = 'black';
      } else if (momentObj.isBefore(moment().startOf('day'), 'day')) {
        colorText = '#34CB61';
      }
      const fullName = object?.user
        ? `${object?.user?.firstName} ${object.user.lastName}`
        : '';
      return <div style={{ color: colorText }}> {object.user && fullName}</div>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'status',
    width: 79,
    render: (text: any, object: any) => {
      const momentObj = moment(object.endDate); // Convert backend date string to Moment object
      const currentDate = moment();
      let colorText = '';
      if (
        (object.PSUSA_status === '' || object.PSUSA_status === null) &&
        (object.jobFrom === '' || object.jobFrom === null)
      ) {
        colorText = 'red';
      } else if (
        object.PSUSA_status === 'Disabled' &&
        object?.jobFrom == 'Admin Panel'
      ) {
        colorText = 'black';
      } else if (momentObj.isBefore(moment().startOf('day'), 'day')) {
        colorText = '#34CB61';
      }

      return (
        <div style={{ color: colorText, textAlign: 'center' }}>
          {object?.PSUSA_status == 'Enabled' ? (
            <StatusSuccess />
          ) : (
            <StatusFailed />
          )}
        </div>
      );
    },
  },
];
